'use client';
import SafeImage from '@ui/SafeImage';
import { ProductDocument } from 'models/product';
import { HeartIcon } from './icons';
import { MotionButton } from 'motion';
import { useState } from 'react';
import { useAuth } from 'context/AuthContext';
import { handleLikeClick } from '@ui/ProductCarousel';
import { useQueryClient } from '@tanstack/react-query';
import { usePathname, useRouter } from 'next/navigation';
import { formatCurrency } from '@util/index';
import Link from 'next/link';
import { getProductSlug } from '@util/urlHelpers';
import {
  trackUserInteraction,
  trackUserSearch,
} from '@util/firestore/recommendations';
import { useSearch } from 'context/SearchContext';

type MXSmallProductCardProps = {
  product: ProductDocument;
};

export default function MXSmallProductCard({
  product,
}: MXSmallProductCardProps) {
  const [isFavorite, setIsFavorite] = useState(false);
  const { userDoc } = useAuth();
  const queryClient = useQueryClient();
  const router = useRouter();
  const pathname = usePathname();
  const { filters } = useSearch();
  const isLiked = userDoc?.favorites.includes(product.id);

  if (!product) return null;

  return (
    <Link
      href={getProductSlug(product)}
      onClick={() => {
        if (pathname?.includes('search') || pathname?.includes('product')) {
          if (userDoc)
            trackUserInteraction({
              uid: userDoc.uid,
              pid: product.id,
              interaction: 'search_clicked',
            });
          if (filters?.term) {
            trackUserSearch({
              ...filters,
              product_clicked: product.id,
            });
          }
        }
      }}
    >
      <div className="relative h-[32.8rem] w-[20rem]">
        <div className="absolute left-64 right-4 top-4 sm:top-2">
          <MotionButton
            className={`flex h-[3rem] w-[3rem] items-center justify-center rounded-full ${
              !isLiked ? 'bg-white' : 'bg-brand-secondary'
            } p-2`}
            whileTap={{ scale: 0.8 }}
            animate={{
              scale: [1, 1.2, 1],
              transition: {
                duration: 0.3,
                times: [0, 0.5, 1],
                ease: 'easeInOut',
              },
            }}
            aria-label="Like product"
            onClick={(e) => {
              if (userDoc) {
                handleLikeClick(
                  e,
                  userDoc,
                  product.id,
                  setIsFavorite,
                  isFavorite,
                  queryClient
                );
              } else {
                router.push('/login');
              }
            }}
          >
            <HeartIcon
              className={`h-full w-full ${
                isLiked ? '!fill-white text-white' : 'text-black'
              }`}
              aria-label="Like product"
            />
          </MotionButton>
        </div>

        <div className="flex h-[20rem] max-h-[20rem] items-center justify-center bg-[#F6F6F6]">
          <SafeImage
            alt={product.title}
            src={product.thumbnail}
            fallbackSrc={product.images[0].download_url}
            className="h-full max-h-[20rem] w-full object-cover"
          />
        </div>

        <div className="mt-4 flex flex-col gap-y-2">
          <h1 className="line-clamp-2 h-[5rem] text-[1.6rem] font-semibold leading-[2.4rem]">
            {product.title}
          </h1>

          {userDoc?.roles?.admin && (
            <span className="text-[#444444]">{product.category}</span>
          )}

          <div className="flex w-full items-center justify-between">
            <span className="text-[2.1rem] font-bold text-black">
              {formatCurrency(product.price)}
            </span>

            <div className="flex items-center justify-center rounded-[1rem] bg-[#EDEDED] px-4 py-2 font-semibold text-black">
              {product.condition}
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}
